import React, { Fragment } from 'react';

export default function PolitiqueConfidentialite() {
  return <Fragment>
    <div className="container-mini">
      <h1>Politique de confidentialité</h1>
      <div class="text-cg-wrapper">
        <p>D’une manière générale, les informations personnelles concernant les visiteurs de notre site et de notre web app, y compris leur identité, sont confidentielles. Le responsable du site s’engage sur l’honneur à respecter les conditions légales de confidentialité applicables en France et en Europe, selon la Réglementation Générale sur la Protection des Données personnelles (RGPD) et à ne pas divulguer ces informations à des tiers.</p>
        <p>Les données personnelles recueillies sur la web app résultent de la communication volontaire d'une adresse de courrier électronique lors de votre connexion. Les adresses E-mail ainsi recueillies ne servent qu’à permettre l’accès au support et outils de la web app. Nous garantissons à chaque personne la confidentialité des informations qui nous sont transmises. Seules pourraient être transmises les informations pour lesquelles une autorisation de transmission aura été donnée.</p>
        <p>Dans tous les cas, seul les Société d’Economie Alpestre sont destinataires de vos Informations Personnelles. Celles-ci, que ce soit sous forme individuelle ou agrégée, ne sont jamais transmises à un tiers, ni utilisées pour un quelconque but commercial.</p>
        <p>Les Sociétés d'Economie Alpestre de Savoie et Haute-Savoie n’utilisent pas de procédés de collecte automatisée de données (cookies, applet java ou active X).</p>
      </div>
      <div className="mentions-legales-randonneurs"><img src="/images/randonneurs.png" aria-hidden={true} alt="" /></div>
      <img src="/images/chien.png" className="perso-footer perso-footer-chien" aria-hidden={true} alt="" />
    </div>
  </Fragment>
}
